import React from "react";
import alienRed from "./static/img/alien_red.png";
// import ladyImg from "./static/img/lady.png";
import badLadyImg from "./static/img/lady-devil.png";
import axios from "axios";
import "./App.css";

const phoneEmojis = ["👾", "👽", "🛸", "🚀", "🪐"];
const emojiWidth = 50;

const getRandomFromArray = (array: any[]): any =>
  array[Math.floor(Math.random() * array.length)];

const getRandomEmojiArray = () => {
  const width = Math.min(global.window?.innerWidth ?? 0, 1000);
  const arr = new Array(Math.floor(width / emojiWidth))
    .fill(0)
    .map(() => getRandomFromArray(phoneEmojis));
  return arr;
};

const submit_url = "/.netlify/functions/submit";

enum SUBMIT_STATE {
  INITIAL = "INITIAL",
  SUBMITTING = "SUBMITTING",
  ERRORED = "ERRORED",
  SUCCESS = "SUCCESS",
}

export default function Index() {
  const [emojis, setEmojis] = React.useState<string[]>(getRandomEmojiArray());
  const [message, setMessage] = React.useState<string>("");
  const [submitState, setSubmitState] = React.useState(SUBMIT_STATE.INITIAL);
  const devilAudio = React.useRef<HTMLAudioElement>(null);

  React.useEffect(() => {
    const rotate = () => {
      setEmojis(getRandomEmojiArray());
    };

    const loop = setInterval(rotate, 600) as unknown as number;

    return () => {
      clearInterval(loop);
    };
  }, []);

  const submit = () => {
    if (message) {
      setSubmitState(SUBMIT_STATE.SUBMITTING);
      axios
        .post(submit_url, { message })
        .then(() => {
          setSubmitState(SUBMIT_STATE.SUCCESS);
          devilAudio.current?.play();
        })
        .catch(() => {
          setSubmitState(SUBMIT_STATE.ERRORED);
        });
    }
  };

  return (
    <main className="relative flex min-h-screen flex-col items-center px-4">
      <h1 className="text-shadow-white my-8 text-center font-barrio text-5xl text-shadow">
        Audio Slop
      </h1>
      <p className="text-center text-2xl text-shadow">
        Now collecting stories and thoughts about:
      </p>
      <h3 className="text-shadow-white my-6 text-center font-barrio text-6xl md:text-6xl lg:text-7xl xl:text-8xl text-shadow">
        ALIENS
      </h3>
      <h3 className="text-4xl">{emojis.join("")}</h3>
      <p className="max-w-3xl py-4 text-lg text-shadow">
        Tell us about when you felt like an alien. Do you believe they're out
        there, why? If we knew about aliens would that be a good thing? Tell us
        anything about aliens.
      </p>
      <div className="flex w-full flex-col items-center max-w-3xl">
        <p className="text-center text-2xl my-5 leading-loose text-shadow">
          Call&nbsp;
          <a
            href="tel:3853554896"
            className="whitespace-nowrap text-white bg-blood hover:bg-red-800 border-solid border-black border-4 rounded-xl p-3 [text-shadow:none;]"
          >
            (385) 355-4896
          </a>
          &nbsp;to leave a voice message or type below
        </p>
        <audio src="/oh-death.mp3" preload="auto" ref={devilAudio} />
      </div>
      {submitState === SUBMIT_STATE.SUCCESS ? (
        <div className="flex w-full flex-col items-center max-w-3xl">
          <img src={badLadyImg} alt="" className="w-48 shake" />
          <p className="text-center text-2xl my-5 leading-loose text-shadow">
            Hell yeah thanks for the free content sucker!
            <br />
            Refresh if you wanna slop up even more!
          </p>
        </div>
      ) : (
        <form
          className="flex w-full flex-col items-center px-3 pb-16"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="flex w-full max-w-5xl flex-col">
            <div className="flex flex-col items-center">
              <div className="relative w-full max-w-3xl mt-6">
                <img className="bottom-left-image" src={alienRed} alt="" />
                <img className="bottom-right-image" src={alienRed} alt="" />
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="fancy-textarea w-full max-w-3xl p-7"
                  maxLength={2000}
                  placeholder="Write ya stuff here and slap that submit button when you're done (max 2000 characters)"
                  disabled={submitState === SUBMIT_STATE.SUBMITTING}
                />
              </div>
              <p className="max-w-3xl py-4 pt-0 text-sm mb-0 italic mt-4 text-shadow">
                Submissions are anonymous unless you include your name. Whatever
                you submit we might use verbatim or alter, but leave your
                contact info if you want a back and forth. This is an art
                project and we'll probably do whatever with it, there's no
                rules, so just know that going in. Also if you submit something
                it means you're giving us unrestricted rights to use it however
                we want yada yada. Thanks champ, submit as many as you want
                doesn't matter to us. Just don't go overboard please because we
                don't want to have to worry about web security and all that.
              </p>
              {submitState === SUBMIT_STATE.ERRORED && (
                <p className="text-red-500 font-bold text-center text-lg py-4 w-full max-w-3xl text-shadow">
                  Something went horribly wrong and it didn't submit. We'll be
                  sure to fire whoever's responsible. Uhhh, try again I guess?
                </p>
              )}
              <button
                type="button"
                className="submit-button mt-2 rounded-xl border-4 border-solid border-black bg-blood hover:bg-red-800 px-5 py-3 text-4xl font-bold text-white"
                disabled={submitState === SUBMIT_STATE.SUBMITTING}
                onClick={submit}
              >
                {submitState === SUBMIT_STATE.SUBMITTING
                  ? "Submitting..."
                  : "Submit"}
              </button>
            </div>
          </div>
        </form>
      )}
    </main>
  );
}
